<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>

    <v-card class="page-card-wrapper mt-0">
      <v-card-title class="px-0">{{ $t('text.newsAndEvents') }}</v-card-title>
      <v-card-subtitle class="px-0"
        >{{ $t('text.newsAndEventsNewSubtitle') }}
      </v-card-subtitle>
      <v-form ref="newNewsAndEventsForm">
        <v-divider></v-divider>
        <template v-for="(item, index) in formFields">
          <div :key="index" class="my-2">
            <v-row no-gutters>
              <v-col cols="5" class="pt-6">
                <label class="text-capitalize">
                  {{ $t(`text.${item.label}`) }}
                </label>
              </v-col>
              <v-col cols="7">
                <v-row no-gutters>
                  <v-col :cols="item.cols" :lg="item.lg">
                    <AppFormField
                      ref="formField"
                      v-model="item.model"
                      :outlined="true"
                      :dense="true"
                      :type="item.type"
                      :items="item.items"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                      :range="item.range"
                      :maxDate="item.maxDate"
                      :rules="
                        !item.optional
                          ? item.rules
                          : item.model
                          ? item.rules
                          : []
                      "
                      :rows="item.rows"
                      class="pt-3"
                    ></AppFormField>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </template>
        <div class="d-flex justify-end mt-6">
          <v-btn
            depressed
            outlined
            class="text-caption mr-2"
            @click="actionResetForm"
            >{{ $t('text.reset') }}</v-btn
          >
          <v-btn
            depressed
            min-width="100"
            color="primary"
            class="text-caption"
            @click="actionAddForm"
            >{{ $t('text.add') }}</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import AppChildPageHeader from '@/components/base/AppChildPageHeader'
import commonMixin from '@/mixins/common.mixin'
import crudMixin from '@/mixins/crud.mixin'
import newsAndEventsMixin from '@/mixins/newsAndEvents.mixin'
import { ROUTE_NAME, FORM_FIELD_TYPE } from '@/constants'
import { formValidationHelper, i18nHelper } from '@/utils'
import {
  NEWS_AND_EVENTS_CREATE_NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_INITIAL_CREATE_NEWS_AND_EVENTS_STATE
} from '@/store/news-and-events.module'

export default {
  name: 'NewsAndEventsNew',
  components: {
    AppChildPageHeader
  },
  mixins: [commonMixin, crudMixin, newsAndEventsMixin],
  data: () => ({
    validation: formValidationHelper,
    pageTitle: 'newNewsAndEvents',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'newsAndEvents',
        disabled: false,
        to: { name: ROUTE_NAME.NEWS_AND_EVENTS_LIST }
      },
      {
        text: 'new',
        disabled: true
      }
    ],
    formFields: [
      {
        model: '',
        label: 'title',
        key: 'title',
        rules: formValidationHelper.requiredRules(),
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'link',
        key: 'link',
        rules: formValidationHelper.urlRules(),
        lg: 12,
        cols: 12,
        optional: true
      },
      {
        model: '',
        label: 'description',
        key: 'description',
        rules: formValidationHelper.requiredRules(),
        lg: 12,
        cols: 12,
        type: FORM_FIELD_TYPE.RICH_TEXT,
        rows: 2
      }
    ],
    actions: {
      createRecord: {
        module: 'newsAndEvents',
        state: 'newNewsAndEvents',
        action: NEWS_AND_EVENTS_CREATE_NEWS_AND_EVENTS,
        title: i18nHelper.getMessage('text.newNewsAndEvents'),
        successAction: () => {}
      },
      initialCreateRecordState:
        NEWS_AND_EVENTS_INITIAL_CREATE_NEWS_AND_EVENTS_STATE
    }
  }),
  created() {
    this.initAdminUserNew()
  },
  methods: {
    actionAddForm() {
      if (this.$refs.newNewsAndEventsForm.validate()) {
        let data = this.mapArrayToObjectData(this.formFields)

        data.link = data.link ? this.checkUrlProtocol(data.link) : null

        this.requestCreate(data)
      }
    },
    actionResetForm() {
      this.$refs.newNewsAndEventsForm.reset()
    },
    initAdminUserNew() {
      this.actions.createRecord.successAction = () => {
        this.backToParentPage(ROUTE_NAME.NEWS_AND_EVENTS)
      }
    }
  }
}
</script>

<style></style>
